import { format } from 'date-fns'
import { matchPersonType } from 'shared/matchData/matchData'

export const prescribedProfilesMapper = (prescribedProfiles) => {
	return prescribedProfiles?.map((item) => {
		return {
			id: item._id,
			profile: item.businessName,
			personType: matchPersonType(item.personType),
			createdAt: format(new Date(item.createdAt), 'dd/MM/yyyy'),
		}
	})
}
