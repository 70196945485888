export const prescribedProfilesColumns = () => {
	return [
		{
			header: 'Perfil Referido',
			accessor: 'profile',
		},
		{
			header: 'Persona',
			accessor: 'personType',
		},
		{
			header: 'Fecha de Inscripción',
			accessor: 'createdAt',
		},
	]
}
