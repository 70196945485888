export const prescribedInvestmentsColumns = () => {
	return [
		{
			header: 'Perfil',
			accessor: 'investorProfile',
			size: 'md',
		},
		{
			header: 'Proyecto',
			accessor: 'projectAddress',
			size: 'md',
		},
		{
			header: 'Importe',
			accessor: 'amount',
		},
		{
			header: 'Fecha Inversión',
			accessor: 'incomeRecivedDate',
		},
		{
			header: 'Fecha Liquidación',
			accessor: 'liquidatedDate',
		},
		{
			header: 'Inicio',
			accessor: 'startDate',
		},
		{
			header: 'Fin',
			accessor: 'endDate',
		},
		{
			header: 'Días',
			accessor: 'days',
		},
		{
			header: 'Honorarios',
			accessor: 'bonus',
		},
		{
			//FOR EXCEL
			header: 'Total importe de Inversión',
			accessor: 'totalImport',
			hidden: true,
		},
		{
			//FOR EXCEL
			header: 'Total Honorarios',
			accessor: 'totalBonus',
			hidden: true,
		},
		// {
		//   header: 'Prescripción liquidada',
		//   accessor: 'prescriptionLiquidatedDate',
		// },
	]
}
