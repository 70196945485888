import { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Attachment } from '@material-ui/icons'
import { CoPresent } from '@mui/icons-material'
import CustomWarningMessage from 'components/CustomWarningMessage/CustomWarningMessage'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import profilePrescriberColumns from './helpers/profilePrescriberColumns'
import { formatCurrency } from 'shared/helpers/currency-helpers'
import { getPrescribersByProfiles } from 'shared/mappers/prescriber-mappers'
import { excelMyPrescribedInvestmentMapper } from 'shared/mappers/prescriber-mappers'
import { getPrescriberProfileListById } from 'local_redux/actions/prescriberActions'
import styles from './styles/profilePrescriberScreenStyles'

const useStyles = makeStyles(styles)

const ProfilePrescriberScreen = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const tableId = 10

	const [data, setData] = useState([])

	const { getFilteredDataRows, getFilteredDataColumns } = useContext(FilterReactVirtualizedTableContext)

	const { loadingPrescriberProfileListById, successPrescriberProfileListById, prescribers } = useSelector(
		(state) => state.prescriberProfileList,
	)
	useEffect(() => {
		dispatch(getPrescriberProfileListById())
	}, [dispatch])
	useEffect(() => {
		if (successPrescriberProfileListById) {
			const prescriber = getPrescribersByProfiles(prescribers)
			setData(prescriber)
		}
	}, [successPrescriberProfileListById])

	const totalsInversions = data.reduce(
		(acc, item) => {
			return {
				amount: acc.amount + Number(item.amount.replace('€', '').replace('.', '').replace(',', '.')),
				bonus: acc.bonus + Number(item.bonus.replace('€', '').replace('.', '').replace(',', '.')),
			}
		},
		{
			amount: 0,
			bonus: 0,
		},
	)

	return (
		<>
			<GridContainer>
				<GridItem xs={12} className={classes.rootItem}>
					{loadingPrescriberProfileListById ? (
						<GridItem xs={12} className={classes.loader}>
							<Loader />
						</GridItem>
					) : prescribers && prescribers?.prescribers !== 0 ? (
						<>
							<Card>
								<CardHeader color='primary' icon>
									<CardIcon color='primary'>
										<CoPresent />
									</CardIcon>
									<h4 className={classes.counterData}>
										Perfiles Prescritos: <strong>{prescribers?.prescribers}</strong>
									</h4>
									{Boolean(getFilteredDataRows({ tableId })?.length) && (
										<ExcelDefaultExport
											excelName='Lista de Inversiones de Referidos'
											tableColumns={getFilteredDataColumns({ tableId })}
											data={excelMyPrescribedInvestmentMapper({ filteredRows: getFilteredDataRows({ tableId }) })}
										/>
									)}
								</CardHeader>
								<CardBody>
									{prescribers?.investments?.length ? (
										<ReactVirtualizedTable
											tableId={tableId}
											columns={profilePrescriberColumns()}
											data={data}
											loading={Boolean(loadingPrescriberProfileListById)}
											success={Boolean(successPrescriberProfileListById)}
											error={''}
											emptyText={'No se encontraron inversiones.'}
										/>
									) : (
										<CustomWarningMessage text='No se encontraron inversiones de ningúno de tus perfiles prescriptores' />
									)}
								</CardBody>
							</Card>
							<GridContainer>
								<GridItem xs={12} sm={8} md={4}>
									<Card className={classes.cardStyle}>
										<CardHeader color='primary' icon>
											<CardIcon color='primary'>
												<Attachment />
											</CardIcon>
										</CardHeader>

										<>
											<CardBody>
												<h4 className={classes.cardTitle}>Inversiones Activas</h4>
												<p>
													Importe invertido: <strong>{formatCurrency(totalsInversions.amount)}</strong>
												</p>
												<p>
													Importe Comisión: <strong>{formatCurrency(totalsInversions.bonus)}</strong>
												</p>
											</CardBody>
										</>
									</Card>
								</GridItem>
							</GridContainer>
						</>
					) : (
						<CustomWarningMessage text='No se encontraron perfiles prescriptores' />
					)}
				</GridItem>
			</GridContainer>
		</>
	)
}

export default ProfilePrescriberScreen
