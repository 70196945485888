import { Tooltip } from '@material-ui/core'
import { Visibility } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import OptionsMenuTable from 'components/OptionsMenuTable/OptionsMenuTable'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import { openModal } from 'shared/helpers/modal-helper'
import { limitText } from 'shared/helpers/limitText'

export const projectLiquiditiesLiquidatedColumns = ({
	userInfo,
	setOpenReinvestmentModal,
	setShowReinvestAdminInfo,
	setOpenReinvestmentPDLModal,
	handlePayStatus,
	finallyPaid,
	setShowGenerateRCModal,
	setIdForRetentionCertificate,
	setAddressProject
}) => {
	return [
		{
			header: 'Perfil Inversor',
			size: 'sm',
			accessor: 'inversor',
		},
		{
			header: 'Email',
			size: 'sm',
			accessor: 'profileEmail',
			nullValue: 'Sin email',
			size: 'sm',
		},
		{
			header: 'Dirección de Proyecto',
			size: 'md',
			accessor: 'projectAddress',
		},
		{
			header: 'Importe',
			size: 'xs',
			accessor: 'import',
		},
		{
			header: 'Intereses',
			size: 'xs',
			accessor: 'typeOfInterest',
		},
		{
			header: 'Beneficios',
			accessor: 'benefits',
			type: 'currency',
			size: 'xs',
		},
		{
			header: 'Retención',
			accessor: 'retention',
			size: 'xs',
		},
		{
			header: 'Importe de Retención',
			accessor: 'retentionAmount',
			type: 'currency',
			size: 'sm',
		},
		{
			header: 'A Pagar',
			accessor: 'amountToPay',
			size: 'sm',
		},
		{
			header: 'Fecha de Registro',
			size: 'sm',
			accessor: 'registerDate',
		},
		{
			header: 'Estado de Contrato',
			size: 'sm',
			accessor: 'contractUrl',
			cell: ({ row }) => {
				if (row.contractUrl) {
					return (
						<div>
							Firmado
							<Tooltip id='tooltip-top' title='Ver Contrato' placement='bottom'>
								<Button
									justIcon
									round
									simple
									onClick={() => window.open(getUrlToOpenFile(row.contractUrl, userInfo))}
									color='success'
									className='view'
								>
									<Visibility />
								</Button>
							</Tooltip>
						</div>
					)
				} else {
					return <div>Pendiente de Firma</div>
				}
			},
		},
		{
			header: 'Ingreso Recibido',
			size: 'sm',
			accessor: 'incomeRecivedDate',
		},
		{
			header: 'Gestionado por',
			size: 'sm',
			accessor: 'user',
		},
		{
			header: 'Fecha Liquidación',
			accessor: 'dateLiquidation',
			nullValue: 'Fecha no Registrada',
			size: 'sm',
		},
		{
			header: 'Fondos',
			size: 'sm',
			accessor: 'liquidation',
			cell: ({ row }) => {
				return (
					<Tooltip title={row.liquidation}>
						<div>{finallyPaid === row.id ? 'Finalmente Pagado' : limitText(row.liquidation, 60)}</div>
					</Tooltip>
				)
			},
		},
		{
			header: 'Acciones',
			size: 'sm',
			accessor: 'actions',
			cellAlign: 'right',
			headerAlign: 'right',
			cell: ({ row }) => {
				// Safely check for nested properties
				const isReInvestment = !(
					(row.action?.liquidationFields?.liquidation === 'reinvest' ||
						row.action?.liquidationFields?.liquidation === 'benefits-only') &&
					!row.action?.wasInvestment &&
					row.action?.status !== 'deleted'
				)

				return (
					<div className='actions-right'>
						<OptionsMenuTable
							options={[
								<div
									disabled={isReInvestment}
									onClick={() =>
										row.action && openModal(row.action, setShowReinvestAdminInfo, setOpenReinvestmentPDLModal)
									}
								>
									Reinvertir en PDL
								</div>,
								<div
									disabled={isReInvestment}
									onClick={() =>
										row.action && openModal(row.action, setShowReinvestAdminInfo, setOpenReinvestmentModal)
									}
								>
									Reinvertir en Co-Inversión
								</div>,
								<div
								onClick={() => {
									setShowGenerateRCModal(true)
									setIdForRetentionCertificate(row.id)
									setAddressProject(row.projectAddress)
								}}
							>
								Descargar Certificado de Retenciones
							</div>,
							].concat(
								(row.action?.liquidationFields?.liquidation === 'reinvest' ||
									row.action?.liquidationFields?.liquidation === 'benefits-only') &&
									!row.action?.wasInvestment &&
									finallyPaid !== row.id ? (
									<div onClick={() => handlePayStatus(row)}>Finalmente pagados</div>
								) : (
									[]
								),
							)}
						/>
					</div>
				)
			},
		},
	]
}
export const projectLiquiditiesLiquidatedExcelColumns = () => {
	return [
		{
			header: 'Perfil Inversor',
			size: 'sm',
			accessor: 'inversor',
		},
		{
			header: 'Email',
			size: 'sm',
			accessor: 'profileEmail',
			nullValue: 'Sin email',
			size: 'sm',
		},
		{
			header: 'Dirección de Proyecto',
			size: 'md',
			accessor: 'projectAddress',
		},
		{
			header: 'Importe',
			size: 'xs',
			accessor: 'import',
		},
		{
			header: 'Intereses',
			size: 'xs',
			accessor: 'typeOfInterest',
		},
		{
			header: 'Beneficios',
			accessor: 'benefits',
			type: 'currency',
			size: 'xs',
		},
		{
			header: 'Retención',
			accessor: 'retention',
			size: 'xs',
		},
		{
			header: 'Importe de Retención',
			accessor: 'retentionAmount',
			type: 'currency',
			size: 'sm',
		},
		{
			header: 'A Pagar',
			accessor: 'amountToPay',
			size: 'sm',
		},
		{
			header: 'Fecha de Registro',
			size: 'sm',
			accessor: 'registerDate',
		},
		{
			header: 'Estado de Contrato',
			size: 'sm',
			accessor: 'contractUrl',
			cell: ({ row }) => {
				if (row.contractUrl) {
					return 'Firmado'
				} else {
					return 'Pendiente de Firma'
				}
			},
		},
		{
			header: 'Ingreso Recibido',
			size: 'sm',
			accessor: 'incomeRecivedDate',
		},
		{
			header: 'Gestionado por',
			size: 'sm',
			accessor: 'user',
		},
		{
			header: 'Fondos',
			size: 'sm',
			accessor: 'liquidation',
			cell: ({ row }) => {
				return (
					<Tooltip title={row.liquidation}>
						<div>{limitText(row.liquidation, 60)}</div>
					</Tooltip>
				)
			},
		},
		{
			header: 'Fecha Liquidación',
			accessor: 'dateLiquidation',
			nullValue: 'Fecha no Registrada',
			size: 'sm',
		},
	]
}
