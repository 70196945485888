const styles = {
	counterData: {
		color: 'black',
		margin: '15px',
	},
	loader: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '10px',
	},
	cardTitle: {
		fontSize: '16px',
		fontWeight: 'bold',
	},
}

export default styles
