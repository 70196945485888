import { Check, Remove } from '@material-ui/icons'
import Tippy from '@tippyjs/react'
import { Tooltip } from '@mui/material'
import { Visibility, HistoryEdu } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'

export const prescriberListColumns = ({ classes, handleOver, profiles, setOpenSignContractModal }) => {
	return [
		{
			header: 'Perfil Prescriptor',
			accessor: 'businessName',
			size: 'md',
			cell: ({ row }) => {
				return (
					<div onMouseOver={(e) => handleOver(e, row)} className={classes.tippyHover}>
						<Tippy
							popperOptions={{ strategy: 'fixed' }}
							className={classes.tippy}
							placement='left'
							zIndex={10000}
							content={
								<GridContainer>
									<GridItem xs={12}>
										<p className={classes.tippyTitle}>Perfiles Prescritos por {row.businessName}</p>
									</GridItem>
									{profiles &&
										profiles.map((profile) => (
											<GridItem xs={12} key={profile._id}>
												<p className={classes.tippyItem}>- {profile.businessName}</p>
											</GridItem>
										))}
								</GridContainer>
							}
						>
							<span>{row.businessName}</span>
						</Tippy>
					</div>
				)
			},
		},
		{
			header: 'Persona',
			accessor: 'personType',
		},
		{
			header: 'Contrato Firmado',
			accessor: 'signedContract',
			cell: ({ row }) => {
				return row.signedContract ? (
					<Tooltip title='Contrato Firmado' placement='right'>
						<Check color='primary' fontSize='small' />
					</Tooltip>
				) : (
					<Tooltip title='Sin Contrato' placement='right'>
						<Remove color='disabled' fontSize='small' />
					</Tooltip>
				)
			},
		},
		{
			header: 'Referidos',
			accessor: 'prescriberProfiles',
			size: 'sm',
			cell: ({ row }) => {
				return (
					<div
						onClick={() => window.open(`/admin/investor-prescriber-list?profileId=${row?.id}`)}
						className={classes.showProfile}
					>
						{row?.prescriberProfiles}
					</div>
				)
			},
		},
		{
			header: 'Acciones',
			accessor: 'actions',
			size: 'sm',
			cellAlign: 'right',
			headerAlign: 'right',
			cell: ({ row }) => (
				<div className='actions-right'>
					{!row.prescriptorContractUrl && (
						<Tooltip title='Firmar Contrato' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => setOpenSignContractModal(true)}
								color='success'
								className='edit'
							>
								<HistoryEdu />
							</Button>
						</Tooltip>
					)}
					<Tooltip title='Ver Inversiones' placement='bottom'>
						<Button
							justIcon
							round
							simple
							onClick={() => window.open(`/admin/investor-prescriber-list?referalId=${row.id}`)}
							color='success'
							className='edit'
						>
							<Visibility />
						</Button>
					</Tooltip>
				</div>
			),
		},
	]
}
