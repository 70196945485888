import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, makeStyles, CircularProgress } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { getRetentionCertificateByPDLId } from 'local_redux/actions/projectLiquidityActions'
import { GET_RETENTION_CERTIFICATE_BY_PDL_RESET } from 'local_redux/constants/projectLiquidityConstants'
import styles from '../styles/generateRCForLiquitedInvestmentModalStyles'

const useStyles = makeStyles(styles)

const GenerateRCForLiquitedPDLModal = ({ open, close, id , projectAddress }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const {
		loadingRetentionCertificateByPdlId,
		successRetentionCertificateByPdlId,
		retentionCertificate,
		errorRetentionCertificateByPdlId,
	} = useSelector((state) => state.retentionCertificateByPDLId)

	useEffect(() => {
		if (id) {
			dispatch(getRetentionCertificateByPDLId(id, projectAddress))
		}
		return () => {
			dispatch({ type: GET_RETENTION_CERTIFICATE_BY_PDL_RESET })
		}
	}, [id])
	useEffect(() => {
		if (successRetentionCertificateByPdlId && retentionCertificate?.certificate) {
			// Convert base64 to Blob
			const base64Data = retentionCertificate.certificate.split(',')[1] || retentionCertificate.certificate;
			const byteCharacters = atob(base64Data);
			const byteNumbers = new Array(byteCharacters.length);
			
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			
			const byteArray = new Uint8Array(byteNumbers);
			const blob = new Blob([byteArray], { type: 'application/pdf' });
			
			// Create blob URL
			const blobUrl = URL.createObjectURL(blob);
			
			// Open in new window
			const pdfWindow = window.open(blobUrl);
			
			// Clean up blob URL when window closes
			if (pdfWindow) {
				pdfWindow.onunload = () => URL.revokeObjectURL(blobUrl);
			}
			
			close();
		}
	}, [successRetentionCertificateByPdlId]);

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={open}
			keepMounted
			onClose={close}
			aria-labelledby='investment-modal-download-title'
			aria-describedby='investment-modal-download-description'
		>
			<DialogTitle id='investment-modal-download-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={close}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Certificado de Retenciones</h4>
			</DialogTitle>

			<DialogContent id='investment-modal-download-description' className={classes.modalBody}>
				<GridContainer>
					{loadingRetentionCertificateByPdlId ? (
						<>
							<GridItem xs={12} className={classes.circularProgress}>
								<CircularProgress />
							</GridItem>
							<GridItem xs={12}>
								<h4 className={classes.textInfo}>
									Espere por favor, se esta generando el Certificado de Retenciones de la inversión...
								</h4>
							</GridItem>
						</>
					) : (
						errorRetentionCertificateByPdlId && (
							<SnackbarContent message={errorRetentionCertificateByPdlId} color='danger' />
						)
					)}
				</GridContainer>
			</DialogContent>
		</Dialog>
	)
}

export default GenerateRCForLiquitedPDLModal
