const profilePrescriberColumns = () => [
	{
		header: 'Perfil',
		accessor: 'investorProfile',
	},
	{
		header: 'Proyecto',
		accessor: 'projectAddress',
	},
	{
		header: 'Importe Invertido',
		accessor: 'amount',
	},
	{
		header: 'Fecha de Inversión',
		accessor: 'incomeRecivedDate',
	},
	{
		header: 'Fecha de Fin',
		accessor: 'endDate',
	},
	{
		header: 'Comisión',
		accessor: 'bonus',
	},
	{
		header: 'Estado',
		accessor: 'status',
	},
	{
		header: 'Tir',
		accessor: 'tir',
	},
]
export default profilePrescriberColumns
