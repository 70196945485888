import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { CoPresent } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import { prescribedProfilesColumns } from '../helpers/prescribed-profiles-columns'
import { prescribedProfilesMapper } from '../mappers/prescribed-profiles-mapper'
import styles from '../styles/prescriberListScreenstyles'

const useStyles = makeStyles(styles)

const ReferealProfilesPrescriber = ({ user }) => {
	const classes = useStyles()

	const [data, setData] = useState([])

	const { loadingPrescriberList, successPrescriberList, errorPrescriberList } = useSelector(
		(state) => state.prescriberList,
	)

	useEffect(() => {
		if (user) {
			const prescribers = prescribedProfilesMapper(user?.prescriberProfiles)
			setData(prescribers)
		}
	}, [user])

	return (
		<GridContainer>
			<GridItem xs={12} className={classes.rootItem}>
				{!user?.prescriberProfiles > 0 ? (
					<GridItem xs={12} className={classes.loader}>
						<Loader />
					</GridItem>
				) : (
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<CoPresent />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>
								Referidos por - <b>{user.businessName}</b>
							</h4>
						</CardHeader>
						<CardBody>
							<ReactVirtualizedTable
								tableId={100}
								columns={prescribedProfilesColumns()}
								data={data}
								loading={Boolean(loadingPrescriberList)}
								success={Boolean(successPrescriberList)}
								error={errorPrescriberList}
								emptyText={'No se encontraron perfiles.'}
							/>
						</CardBody>
					</Card>
				)}
			</GridItem>
		</GridContainer>
	)
}

export default ReferealProfilesPrescriber
