import { cardTitle } from 'assets/jss/material-dashboard-pro-react'

const styles = {
	...cardTitle,
	gridSearch: {
		marginTop: '3rem',
		textAlign: 'center',
	},
	iconSearch: {
		'& svg': {
			fontSize: '130px',
			color: '#999',
		},
	},
	loader: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '10px',
		height: '57vh',
	},
	cardIconTitle: {
		color: '#000',
	},
	tooltip: {
		boxShadow: '0px 2px 20px lightgray',
		zIndex: `${100000}  !important`,
		maxWidth: '250px',
	},
	tippy: {
		boxShadow: '0px 2px 20px lightgray',
		background: '#fff',
		color: 'black',
		padding: '15px',
	},
	tippyHover: {
		cursor: 'pointer',
	},
	tippyTitle: {
		fontSize: '12px',
		textAlign: 'center',
		fontWeight: '600',
	},
	tippyItem: {
		fontSize: '12px',
	},
	showProfile: {
		cursor: 'pointer',
		color: '#4646c3',
	},
	filters: {
		display: 'flex',
		flexDirection: 'column',
	},
}

export default styles
