import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { CoPresent } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import ReferealProfilesPrescriber from './components/ReferealProfilesPrescriber'
import ReferalListByPrescriber from './components/ReferalListByPrescriber'
// import SignContractModal from './components/SignContractModal'
import { excelMapper, prescriberListMappers } from './mappers/prescriberListMappers'
import { prescriberListColumns } from './helpers/prescriberListColumns'
import { getPrescriberList } from 'local_redux/actions/prescriberActions'
import styles from './styles/prescriberListScreenstyles'

const useStyles = makeStyles(styles)

const PrescriberListScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const tableId = 1
	const search = useLocation().search
	const referalQuery = new URLSearchParams(search).get('referalId')
	const prescriberId = new URLSearchParams(search).get('profileId')

	const [data, setData] = useState([])
	const [user, setUser] = useState({})
	const [openSignContractModal, setOpenSignContractModal] = useState(false) // eslint-disable-line no-unused-vars
	const [profiles, setProfiles] = useState([])

	const { getFilteredDataRows, getFilteredDataColumns } = useContext(FilterReactVirtualizedTableContext)
	const { loadingPrescriberList, successPrescriberList, prescriberListData, errorPrescriberList } = useSelector(
		(state) => state.prescriberList,
	)

	useEffect(() => {
		if (!referalQuery && !successPrescriberList) {
			dispatch(getPrescriberList())
		}
	}, [referalQuery, successPrescriberList])
	useEffect(() => {
		if (prescriberListData) {
			const prescriber = prescriberListData.find((prescriber) => prescriber._id === prescriberId)
			setUser(prescriber)
		}
	}, [prescriberListData, prescriberId])
	useEffect(() => {
		if (successPrescriberList) {
			let prescribers = prescriberListData.filter((item) => item.prescriberProfiles.length > 0)
			prescribers = prescriberListMappers(prescribers)
			setData(prescribers)
		}
	}, [successPrescriberList])

	const handleOver = (e, row) => {
		const prescriber = prescriberListData.find((prescriber) => prescriber._id === row.id)
		setProfiles(prescriber.prescriberProfiles)
	}
	// const handleCloseModal = () => {
	// 	setOpenSignContractModal(false)
	// }

	return (
		<GridContainer>
			{referalQuery ? (
				<GridItem xs={12}>
					<ReferalListByPrescriber />
				</GridItem>
			) : prescriberId && user && user.prescriberProfiles ? (
				<GridItem xs={12}>
					<ReferealProfilesPrescriber user={user} />
				</GridItem>
			) : (
				<GridItem xs={12} className={classes.rootItem}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<CoPresent />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Prescriptores</h4>
						</CardHeader>
						<CardBody>
							<GridContainer>
								<GridItem xs={12}>
									{Boolean(getFilteredDataRows({ tableId })?.length) && (
										<ExcelDefaultExport
											excelName='Lista de Prescriptores'
											tableColumns={getFilteredDataColumns({ tableId })}
											data={excelMapper({ filteredRows: getFilteredDataRows({ tableId }) })}
										/>
									)}
								</GridItem>
								<GridItem xs={12} className={classes.downloadButton}>
									<ReactVirtualizedTable
										tableId={tableId}
										columns={prescriberListColumns({
											classes,
											handleOver,
											profiles,
											setOpenSignContractModal,
										})}
										data={data}
										loading={Boolean(loadingPrescriberList)}
										success={Boolean(successPrescriberList)}
										error={errorPrescriberList}
										emptyText={'No se encontraron perfiles con prescriptores.'}
									/>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
			)}
		</GridContainer>
	)
}

export default PrescriberListScreen
