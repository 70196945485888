import { differenceInCalendarDays, format, addDays } from 'date-fns'
import { es } from 'date-fns/locale'
import { investmentLiquidationOptions } from 'shared/general-config/investment-contrants'
import { parseFromStringToCurrencyNumber } from 'shared/helpers/currency-helpers'

const currency = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })

export const projectLiquiditiesLiquidatedMapper = (projects) => {
	const reinvestedIn = (childInvestments) => {
		return childInvestments.map((investment) => ` ${investment?.projectAddress || ''}`).join()
	}
	return projects.map((project) => {
		const liquidationRetention = project.liquidationFields?.retention || project?.profileInvestor?.retention
		const differenceInDays = !!project?.incomeRecivedDate
			? differenceInCalendarDays(new Date(project?.liquitationDate), new Date(project?.incomeRecivedDate))
			: 0
		const fee =
			((Number(project?.amountPdl) * (Number(project?.typeOfInterest?.replace('%', '')) / 100)) / 360) *
			differenceInDays
		const retentionInNumber = Number(liquidationRetention?.replace('%', '')) / 100
		const retentionFee = Number(fee) * retentionInNumber

		let calculateBenefits = fee.toFixed(2)
		try {
			calculateBenefits =
				fee < 1 ?? project?.liquidationFields?.benefit ? project?.liquidationFields?.benefit.toFixed(2) : fee.toFixed(2)
		} catch (error) {
			//console.error(error)
		}

		const calculateRetentionAmount = () => {
			if (retentionFee < 1) {
				try {
					const result = project?.liquidationFields?.retentionAmount
						? project?.liquidationFields?.retentionAmount.toFixed(2)
						: retentionFee.toFixed(2)
					return result
				} catch (error) {
					//
				}
			}
			return retentionFee.toFixed(2)
		}
		const adjustDate = (dateString) => {
			if (dateString.includes('T00:00:00.000Z')) {
				// Check if the date string contains 'T00:00:00.000Z'
				// Add one day to the date
				const adjustedDate = addDays(new Date(dateString), 1)
				return format(adjustedDate, 'dd/MM/yyyy', { locale: es })
			}
			// Return the date as is
			return format(new Date(dateString), 'dd/MM/yyyy', { locale: es })
		}

		return {
			id: project._id,
			inversor: project?.profileInvestor?.businessName || 'Sin nombre',
			profileEmail: project?.profileInvestor?.email,
			projectAddress: project?.projectAddress,
			registerDate: adjustDate(project?.createdAt),
			import: project?.amountPdl && project?.amountPdl ? currency.format(project?.amountPdl) : '0,00 €',
			contractUrl: project.contractUrl,
			incomeRecivedDate: project.incomeRecivedDate
				? format(new Date(project.incomeRecivedDate), 'dd/MM/yyyy', { locale: es })
				: 'Pendiente de confirmar',
			user: project?.user?.name || 'Sin nombre',
			typeOfInterest: project.typeOfInterest,
			status: project,
			retention: liquidationRetention,
			retentionAmount: calculateRetentionAmount(),
			benefits: calculateBenefits,
			amountToPay: project?.liquidationFields?.toPay ? currency.format(project?.liquidationFields?.toPay) : '0,00 €',
			liquidation:
				project?.liquidationFields?.liquidation === investmentLiquidationOptions.finallyPaid
					? 'Finalmente Pagados'
					: project?.liquidationFields?.liquidation === investmentLiquidationOptions.pay && !project?.wasInvestment
					? 'Recibidos'
					: project?.liquidationFields?.liquidation === investmentLiquidationOptions.benefitsOnly &&
					  !project?.wasInvestment
					? 'Solo Beneficios Recibidos'
					: project?.wasInvestment
					? `${
							project?.liquidationFields?.liquidation === investmentLiquidationOptions.reinvest
								? project?.childPdl?.length
									? 'Para PDL del proyecto '
									: 'Reinvertidos en '
								: project?.childPdl?.length
								? 'Importe inicial para PDL del proyecto '
								: 'Importe inicial reinvertido en '
					  } ${reinvestedIn(project?.projectsReinvested)}`
					: 'Pendiente de Reinversión',
			dateLiquidation: project?.liquitationDate && adjustDate(project?.liquitationDate),
			action: project,
		}
	})
}
export const excelMapper = ({ filteredRows }) => {
	return filteredRows?.map((item) => {
		return {
			inversor: item?.inversor?.toUpperCase(),
			projectAddress: item?.projectAddress?.toUpperCase() || 'sin dirección',
			registerDate: item?.registerDate,
			import: parseFromStringToCurrencyNumber(item?.import),
			contractUrl: item?.contractUrl ? 'Firmado' : 'Pendiente',
			incomeRecivedDate: item?.incomeRecivedDate,
			user: item?.user,
			typeOfInterest: item?.typeOfInterest,
			benefits: item?.benefits?.replace('.', ','),
			amountToPay: parseFromStringToCurrencyNumber(item?.amountToPay),
			retention: item?.retention,
			retentionAmount: item?.retentionAmount?.replace('.', ','),
			dateLiquidation: item?.dateLiquidation,
			liquidation: item?.liquidation,
			profileEmail: item?.profileEmail,
		}
	})
}
