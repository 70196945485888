import { Tooltip } from '@mui/material'
import { Edit, Euro, Delete, RotateLeft, SportsScore, Money, Warning, Payments } from '@mui/icons-material'
import { ImportExport } from '@material-ui/icons'
import { amber, red } from '@mui/material/colors'
import Button from 'components/CustomButtons/Button'
import OptionsMenuTable from 'components/OptionsMenuTable/OptionsMenuTable'

export const projectListColumns = ({
	userInfo,
	setConfirmCsvRemake,
	handleOpenDownloadModal,
	setConfirmCSVPDLRemake,
	showUploadDocumentModal,
	showInvesmentByAdminHandler,
	showDeleteInfoHandler,
	setIdProject,
	navigate,
	successProjectRebuildCsv,
	errorProjectRebuildCsv,
	successRebuildProjectLiquidity,
	errorRebuildProjectLiquidity,
	handleLiquidateProjectExcelExport,
	loadingExport,
	classes,
}) => {
	const isAuth = userInfo.isSuper || userInfo.isManagement
	return [
		{
			header: 'Dirección de Proyecto',
			accessor: 'projectAddress',
		},
		{
			header: 'Inversión Total',
			accessor: 'projectCost',
		},
		{
			header: 'Estado del proyecto',
			accessor: 'status',
		},
		{
			header: 'Avisos',
			accessor: 'completionWarningText',
			nullValue: ' ',
			cell: ({ row }) => (
				<>
					{row.completionWarningText && (
						<Tooltip placeholder='top' title={<span style={{ fontSize: '11px' }}>{row.completionWarningText}</span>}>
							<span style={{ cursor: 'pointer' }}>
								{row.completionWarningText === 'Faltan menos de 15 días para el fin.' ? (
									<Warning sx={{ color: amber[500] }} />
								) : (
									<Warning sx={{ color: red[500] }} />
								)}
							</span>
						</Tooltip>
					)}
				</>
			),
		},
		{
			header: 'Acciones',
			accessor: 'actions',
			cellAlign: 'right',
			headerAlign: 'right',
			cell: ({ row }) =>
				isAuth && (
					<div className='actions-right'>
						{row.item.status === 'liquidated' && (
							<>
								<Tooltip
									title={loadingExport ? 'Exportando...' : 'Exportar Co-Inversiones Liquidadas en excel'}
									placement='top-start'
								>
									<Button
										justIcon
										round
										simple
										onClick={() => handleLiquidateProjectExcelExport(row.id)}
										disabled={loadingExport}
										color='success'
									>
										<ImportExport />
									</Button>
								</Tooltip>
								{(row.item?.pdlLiquidate?.csvFilePath || row.item?.pdlLiquidate?.xmlFilePath) && (
									<OptionsMenuTable
										options={[
											<div
												className={classes.dropDownItem}
												onClick={() => {
													handleOpenDownloadModal({
														csvFilePath: row.item?.pdlLiquidate?.csvFilePath,
														label: 'PDLs',
														type: 'CSV',
													})
												}}
												disabled={!row.item?.pdlLiquidate?.csvFilePath}
											>
												Descargar CSV
											</div>,
											<div
												className={classes.dropDownItem}
												onClick={() => {
													handleOpenDownloadModal({
														csvFilePath: row.item?.pdlLiquidate?.xmlFilePath,
														label: 'PDLs',
														type: 'XML',
													})
												}}
												disabled={!row.item?.pdlLiquidate?.xmlFilePath}
											>
												Descargar XML
											</div>,
										]}
										icon={<Payments color='success' />}
										text='Descargar CSV o XML de PDLs Liquidados'
									/>
								)}
								{userInfo.isSupport && (
									<>
										<Tooltip id='tooltip-top' title='Rehacer CSV y XML de PDLs' placement='bottom'>
											<Button
												justIcon
												round
												simple
												onClick={() => setConfirmCSVPDLRemake(row.item)}
												color={
													successRebuildProjectLiquidity
														? 'success'
														: errorRebuildProjectLiquidity
														? 'danger'
														: 'primary'
												}
												className='rebuild-Document'
											>
												<RotateLeft />
											</Button>
										</Tooltip>
										<Tooltip id='tooltip-top' title='Rehacer CSV y XML de Co-inversiones' placement='bottom'>
											<Button
												justIcon
												round
												simple
												onClick={() => setConfirmCsvRemake(row.item)}
												color={successProjectRebuildCsv ? 'success' : errorProjectRebuildCsv ? 'danger' : 'primary'}
												className='rebuild-Document'
											>
												<RotateLeft />
											</Button>
										</Tooltip>
									</>
								)}
							</>
						)}
						{row.item.status === 'liquidated' && row.item.existCsv && (
							<OptionsMenuTable
								options={[
									<div
										className={classes.dropDownItem}
										onClick={() => {
											handleOpenDownloadModal({
												csvFilePath: row.item.csvFilePath,
												label: 'Co-Inversiones',
												type: 'CSV',
											})
										}}
										disabled={!row.item.csvFilePath}
									>
										Descargar CSV
									</div>,
									<div
										className={classes.dropDownItem}
										onClick={() => {
											handleOpenDownloadModal({
												csvFilePath: row.item.xmlFilePath,
												label: 'Co-Inversiones',
												type: 'XML',
											})
										}}
										disabled={!row.item.xmlFilePath}
									>
										Descargar XML
									</div>,
								]}
								icon={<Money color='success' />}
								text='Descargar CSV o XML de Co-Inversiones'
							/>
						)}
						<Tooltip id='tooltip-top' title='Subir/Consultar/Modificar documento de fin de proyecto' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => showUploadDocumentModal(row.item)}
								color='success'
								className='upload-Document'
							>
								<SportsScore />
							</Button>
						</Tooltip>
						<Tooltip id='tooltip-top' title='Invertir' placement='bottom'>
							<Button
								justIcon
								disabled={!row.item.invertible}
								round
								simple
								onClick={() => showInvesmentByAdminHandler(row.item._id)}
								color='success'
								className='toInvest'
							>
								<Euro />
							</Button>
						</Tooltip>
						<Tooltip id='tooltip-top' title='Editar' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => {
									navigate(`/admin/list-projects?project=${row.item._id}`)
								}}
								color='success'
								className='edit'
							>
								<Edit />
							</Button>
						</Tooltip>
						<Tooltip id='tooltip-top' title='Borrar' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => {
									showDeleteInfoHandler(row.item._id), setIdProject(row.item._id)
								}}
								color='danger'
								className='delete'
							>
								<Delete />
							</Button>
						</Tooltip>
					</div>
				),
		},
	]
}
